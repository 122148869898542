/* .my-custom-scrollbar {
    position: relative;
    height: 400px;
    overflow: auto;
    }
    .table-wrapper-scroll-y {
    display: block;
} */
/* thead {
  display:block;
}
tbody {
  display:block;
  overflow-y:scroll;
  height:200px;
}
td, th {
  width: 200px !important;;
}
table {
  width: 820px !important;
}
 */

/*  .mapping_table tbody {
  display:block;
  height:200px;
  overflow-y:scroll;
  }
  .mapping_table thead{
  display:table;
  width:98.7%;
  table-layout:fixed;
  }
  .mapping_table tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
  } */

  .table-container {
    overflow: auto;
    max-height: 300px;
    border: 1px solid red;
  }
  
  th {
    position: sticky;
    top: 0;
    background: white;
  }