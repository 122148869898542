#sidebarMenu li:hover {
    background-color: black !important;
}

.topLink:hover {
    background-color: #0d0d0e!important ; 
    padding-left: 5px;
    padding-right: 5px;
    border: 3px;
}

.last {
    margin-top: auto;
}